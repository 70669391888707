import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const srcs = [
	"https://www.youtube.com/embed/YSi4d-jxklQ",
	"https://www.youtube.com/embed/rcgDaZNDV-k",
	"https://www.youtube.com/embed/Vic97OL6NpE"
]

export class Videos extends Component {
	state = {
		headerText: 'msw'
	}

	headerHover = bool => {
		this.setState({ headerText: bool ? 'marcus scott williams' : 'msw' })
	}

	render () {
		return (
			<main className='videos'>
				<Link to='/'>
					<h1 onMouseOver={() => this.headerHover(true)} onMouseLeave={() => this.headerHover(false)}>
						{this.state.headerText}
					</h1>
				</Link>
				{srcs.map(vid => (
					<iframe
						className='vid'
						key={vid}
						title={vid}
						width="560"
						height="315"
						src={vid}
						frameborder="0"
						allow="accelerometer;
						autoplay;
						encrypted-media;
						gyroscope;
						picture-in-picture"
						allowfullscreen />
				))}
			</main>
		)
	}
}