import React, { Component } from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom'
import { CV } from './CV'
import { Home } from './Home'
import { Videos } from './Videos'
import './App.css'

class App extends Component {
	render () {
		return (
			<Router>
				<div className='App'>
					<Switch>
						<Route path='/cv'>
							<CV />
						</Route>
						<Route path='/vids'>
							<Videos />
						</Route>
						<Route path='/'>
							<Home />
						</Route>
					</Switch>
				</div>
			</Router>
		)
	}
}

export default App
