import React from 'react'
import { Link } from 'react-router-dom'

export const CV = () => {
	return (
		<main className='cv'>
			<div id="page_1">
				<p className="p0 ft0">marcus scott williams</p>
				<p className="p1 ft2">mswyfm@gmail.com <nobr>816-213-9720</nobr> <Link to="/"><span className="ft1">mswthug.life</span></Link></p>
				<p className="p2 ft3">WORK HISTORY</p>
				<p className="p3 ft6">Creative Nonfiction Reader, <span className="ft4">homology</span>​ <span className="ft4">lit., </span>June<span className="ft5"/><nobr>2019-November</nobr> 2019 Bookseller, <span className="ft4">The</span>​ <span className="ft4">Center for Fiction</span>,<span className="ft5">​</span>Brooklyn, NY. February <nobr>2019-Current</nobr></p>
				<p className="p4 ft9">Guest Editor, <span className="ft7">Spy</span>​ <span className="ft7">Kids Review</span>.<span className="ft8">​</span>Issue #5. <span className="ft7">2fast2house</span>​ ,<span className="ft8">​</span>2018</p>
				<p className="p5 ft12">Workshop Creator/Facilitator, “Who Run It: Writers of Color Stay Connected,” via <span className="ft10">Bronx</span>​ <span className="ft10">Council on the Arts</span>,<span className="ft11">​</span>April 2018.</p>
				<p className="p6 ft15"><span className="ft13">Workshop </span><nobr><span className="ft13">Co-Creator/Instructor</span>,<span className="ft13">​</span>Experimental</nobr> Poetry Prompts & Meditation, <span className="ft13">Black</span><span className="ft14">​ </span><span className="ft13">Playground Art Fair, Brooklyn, NY. 2019</span></p>
				<p className="p7 ft3">PUBLISHED WORK</p>
				<p className="p8 ft18">2016 “untitled 5152016,” <span className="ft16">El</span>​ <span className="ft16">Balazo Press Blog</span>.<span className="ft17">​ </span>2016 “untitled 5292016,” <span className="ft16">Sea</span>​ <span className="ft16">Foam Mag</span>.<span className="ft17">​</span></p>
				<p className="p4 ft21">2016 “Nuttyblock” & “shitsaidinharlemhigh,” <span className="ft19">Sula</span>​ <span className="ft19">Collective</span>,<span className="ft20">​</span>​Issue<span className="ft20">​ </span>#13.</p>
				<p className="p9 ft21">2016 “untitled 882016,” <span className="ft19">Spy</span>​ <span className="ft19">Kids Review</span>,<span className="ft20">​</span>2fast2house, Pg <nobr>64-65.</nobr></p>
				<p className="p9 ft21">2017 <span className="ft22">Sparse Black Whimsy: A Memoir</span>,​<span className="ft19">2fast2house</span>​ ,<span className="ft20">​</span>106 pages.</p>
				<p className="p10 ft18">2017 “012217 (RIP Boncourse)’ & ‘2817’, <span className="ft16">tenderness,</span>​ <span className="ft16">yea</span>.<span className="ft17">​ </span>2017 “2117,” <span className="ft16">Sula</span>​ <span className="ft16">Collective: The Spring Issue</span>,<span className="ft17">​</span>Pg 35.</p>
				<p className="p11 ft13">2017 “msw: notebook excerpts,” <span className="ft15">Sula</span>​ <span className="ft15">Collective</span>,<span className="ft14">​ </span>Issue<span className="ft14">​ </span>#16: Cities / The Anniversary Issue. Pgs <nobr>67-73.</nobr></p>
				<p className="p12 ft21">2018 “u know how This Shit go,” <span className="ft19">The</span>​ <span className="ft19">Slanted House Zine</span>,<span className="ft20">​</span>​Issue<span className="ft20">​ </span>#1.</p>
				<p className="p9 ft21">2018 “No Money For MetroCards” & “not for play play,” <span className="ft19">Be</span>​ <span className="ft19">About It Press Blog</span>.<span className="ft20">​</span></p>
				<p className="p9 ft21">2018 “From Top to Ending,” <span className="ft19">Vagabond</span>​ <span className="ft19">City Lit</span>.<span className="ft20">​</span></p>
				<p className="p9 ft21">2019 “allup in my dreams, #1,” <span className="ft19">Sand</span>​ <span className="ft19">Journal, Issue #19</span>.<span className="ft20">​</span></p>
				<p className="p9 ft21">2019 “all generosity,” <span className="ft19">blush</span>​ <span className="ft19">lit</span>.<span className="ft20">​</span></p>
				<p className="p9 ft21">2019 “meadow on Wabash,” <span className="ft19">The</span>​ <span className="ft19">Common.</span></p>
				<p className="p9 ft21">2020 “Crater Lake,” <span className="ft19">The</span>​ <span className="ft19">Common, Issue 19</span></p>
			</div>
			<div id="page_2">
				<p className="p13 ft21">2020 <span className="ft19">say less: a </span><nobr><span className="ft19">memoir</span>.<span className="ft20">​</span>Self-published.</nobr></p>
				<p className="p14 ft23">RESIDENCIES / EXHIBITIONS / FELLOWSHIPS</p>
				<p className="p15 ft21">2017 <span className="ft19">On Collaboration </span>Lecture<span className="ft20">​ </span>. Purchase College. Purchase, New York</p>
				<p className="p16 ft15"><span className="ft13">2017 </span>Bronx Recognizes Its Own (BRIO) Award for Artistic Excellence<span className="ft13">,</span><span className="ft14">​ </span><span className="ft13">Bronx Council on the Arts</span></p>
				<p className="p17 ft18">2017 <span className="ft16">sbw by </span><nobr><span className="ft16">msw</span><span className="ft17">​</span>(pop-up</nobr> solo show), The Ozark Collective, New York City, NY 2018 <span className="ft16">these here spilt voices (group show)</span>.<span className="ft17">​</span>Slanted House. Berlin, Germany</p>
				<p className="p18 ft13">2018 <span className="ft15">Time Keeping and Time Travel</span>,<span className="ft14">​ </span>workshop with <nobr>Anastacia-Reneé,</nobr> Cave Canem Foundation. Brooklyn, NY</p>
				<p className="p19 ft21">2018 <span className="ft19">a re:trospective </span>(group<span className="ft20">​ </span>show), <span className="ft19">home</span>​ <span className="ft19">school / re: Art</span>,<span className="ft20">​ </span>Pfizer Building, Brooklyn, NY</p>
				<p className="p20 ft26">2019 <span className="ft24">Catalyze </span><nobr><span className="ft24">Self-Revolutions</span></nobr><span className="ft24"> in Your Writing</span>,<span className="ft25">​</span>Winter Tangerine Workshops, New York, NY 2019 Fellow, Saltonstall Foundation of the Arts, Ithaca, NY</p>
				<p className="p21 ft19"><span className="ft21">2019 </span>Bushwick Open Studios<span className="ft21">,</span><span className="ft20">​</span><span className="ft21">haul gallery</span></p>
				<p className="p22 ft21">2020 Resident, Vermont Studio Center. Johnson, VT</p>
				<p className="p23 ft21">2020 Resident, Ragdale. Lake Forest, IL</p>
				<p className="p24 ft21">2021 <span className="ft19">solo show.</span><span className="ft20">​</span>haul gallery</p>
				<p className="p25 ft23">BIBLIOGRAPHY</p>
				<p className="p15 ft21">Piñero, Kassandra: “<a href="https://sulacollective.com/2017/04/18/msw-an-interview-by-k-pinero/"><span className="ft27">sbw</span></a><a href="https://sulacollective.com/2017/04/18/msw-an-interview-by-k-pinero/">​ </a><a href="https://sulacollective.com/2017/04/18/msw-an-interview-by-k-pinero/"><span className="ft27">by msw: an interview by k. piñero</span></a>,”<span className="ft27">​ </span>Sula Collective, April 2017</p>
				<p className="p16 ft28"><span className="ft13">Zorko, Greg: “</span><a href="https://maudlinhouse.net/whimsy-city-a-conversation-with-marcus-scott-williams/">Whimsy</a><a href="https://maudlinhouse.net/whimsy-city-a-conversation-with-marcus-scott-williams/"><span className="ft13">​ </span></a><a href="https://maudlinhouse.net/whimsy-city-a-conversation-with-marcus-scott-williams/">City: A Conversation with Marcus Scott Williams</a><span className="ft13">,”</span>​ <span className="ft13">Maudlin House, July 2017</span></p>
				<p className="p26 ft28"><span className="ft13">Bell, Timothy: “</span><a href="https://www.tribes.org/web/2017/11/27/sparse-black-whimsy-an-interview-with-artist-msw">Sparse</a><a href="https://www.tribes.org/web/2017/11/27/sparse-black-whimsy-an-interview-with-artist-msw"><span className="ft13">​ </span></a><a href="https://www.tribes.org/web/2017/11/27/sparse-black-whimsy-an-interview-with-artist-msw">Black Whimsy: An Interview With Artist MSW</a><span className="ft13">,”</span>​ <span className="ft13">A Gathering of the Tribes, November 2017.</span></p>
			</div>
		</main>
	)
}