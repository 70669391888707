import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Home extends Component {
	state = {
		headerText: 'msw'
	}

	headerHover = bool => {
		this.setState({ headerText: bool ? 'marcus scott williams' : 'msw' })
	}

	render () {
		return (
			<main className="home">
				<Link className="navlink" to="/vids">Videos</Link>
				<Link className="navlink last" to="/cv">CV</Link>
				<div className='col'>
					<img alt="bio" className={this.state.headerText !== 'msw' ? 'visible' : 'hidden'} src='/bio.JPG' />
				</div>
				<div className='col'>
					<h1 onMouseOver={() => this.headerHover(true)} onMouseLeave={() => this.headerHover(false)}>
						{this.state.headerText}
					</h1>
					<h4>
						<span>writer</span>
						<span>artist</span>
						<span>real one</span>
						<span>love y'all</span>
					</h4>
					<div className='links-section'>
						<div className='along-lines'>i write shit along these lines:</div>
						<div className='link'>
							<a target='_blank' rel="noopener noreferrer" href='https://www.amazon.com/Sparse-Black-Whimsy-Memoir-Msw/dp/0997875976'>
								sparse black whimsy: a memoir
							</a>
						</div>
						<div className='link'>
							<a target='_blank' rel="noopener noreferrer" href='https://www.instagram.com/flashmemoirs/'>
								flash memoirs
							</a>
						</div>
						<div className='link'>
							<a target='_blank' rel="noopener noreferrer" href='https://www.instagram.com/marcusscottwilliams/'>
								it be like this
							</a>
						</div>
					</div>
				</div>
			</main>
		)
	}
}